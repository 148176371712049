<template>
  <v-container>
    <h2>HÄFTiga Platser</h2>
    <h5>Alla svar rättas efter rallyt och kan ge er tidsavdrag</h5>
    <v-container>
      <div v-if="fetchingResults" class="center">
          <vue-spinner />
      </div>
      <v-row v-else
        no-gutters
        style="height: 100px;"
      >
        <v-col
          cols="12"
          sm="3"
          v-for="n in numHaftigaPlaces"
          :key="n"
        >
          <v-sheet v-if="doneHaftigaPlaces.includes(n)" @click="openSubmitDialog(n)" class="ma-3">
            <v-img class="rounded-lg" :src="getHaftigImageURL(n, false)" />
          </v-sheet>
          <v-sheet v-else @click="openSubmitDialog(n)" class="ma-3">
            <v-img class="rounded-lg" :src="getHaftigImageURL(n, true)" />
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-text class="pt-4">
            <h2 class="primary--text pb-2">HÄFTig plats #{{ h_id }}</h2>
            <h4 v-if="doneHaftigaPlaces.includes(h_id)">Titta! Den här platsen har ni redan skickat in. Ni kan se er bild <a class="text-decoration-underline" target="_blank" :href="this.getImageUrl(h_id)">här.</a></h4>
            <v-form ref="form" lazy-validation>
              <!-- Accept any image or video -->
              <v-file-input
                accept="image/*"
                label="häftigaste_bilden.lovar"
                ref="fileupload"
                @change="uploadHaftigPlace"
                :disabled="haftigSubmissionLoading"
                required
              ></v-file-input>
            </v-form>
            <div v-if="haftigSubmissionLoading" class="center">
              <vue-spinner />
            <p>Skickar in HÄFTig plats...</p>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="primary"
              @click="submitHaftigPlace"
              :disabled="haftigSubmissionLoading"
            >
              Skicka svar
            </v-btn>
            <v-btn
              text
              @click="dialog = false"
              :disabled="haftigSubmissionLoading"
            >
              Stäng
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>



<script>
import { mapGetters, mapActions } from "vuex"
import Spinner from "vue-simple-spinner"
import {notify} from '../../utils/notification'

export default {
  name: 'HaftigPlaces',
  components: {
    vueSpinner: Spinner
  },
  data: () => ({
    numHaftigaPlaces: null,
    dialog: false,
    fileData: null,
    h_id: null, // Should be entered by user
    fileSize: null,
    doneHaftigaPlaces: [],
    doneHaftigaPlacesData: null,
    fetchingResults: true,
    team: null,
    timer: null,
  }),
  methods: {
    ...mapActions(["sendHaftigPlace", "getDoneHaftigaPlaces" ,"clearNotifications", "getTeam"]),
    uploadHaftigPlace(file) {
      let formData = new FormData()
      formData.append("file", file)
      this.fileSize = formData.get("file").size/(1024*1024) // Convert to MB
      this.fileData = formData
    },
    getImageUrl(haftigNumber) {
      return this.doneHaftigaPlacesData[haftigNumber].url
    },
    getHaftigImageURL(haftigNumber, blackAndWhite) {
      if (this.team.t_gamla_rallyt){
        // Special for Rally23
        if (blackAndWhite) {
          return "https://storage.googleapis.com/rally-24/rally23/haftig-" + haftigNumber + "-bw.jpg"
        } else {
          return "https://storage.googleapis.com/rally-24/rally23/haftig-" + haftigNumber + ".jpg"
        }
      } else {
        // Regular
        if (blackAndWhite) {
          return "https://storage.googleapis.com/rally-24/haftig_places_images/haftig-" + haftigNumber + "-bw.jpg"
        } else {
          return "https://storage.googleapis.com/rally-24/haftig_places_images/haftig-" + haftigNumber + ".jpg"
        }
      }
    },
    async submitHaftigPlace() {
      // Delete any previous mission ID
      this.fileData.delete("data")
      // Set new mission ID
      this.fileData.append("data", this.h_id)
      this.fileData.append("filesize", this.fileSize)
      this.sendHaftigPlace(this.fileData).then(async () => {
          await this.updateDoneHaftigaPlaces()
          this.dialog = false
          this.fileData = null
          this.fileSize = null
          this.$refs.fileupload.reset()
          notify(this)
      })
    },
    async updateDoneHaftigaPlaces() {
      const data = await this.getDoneHaftigaPlaces()
      this.doneHaftigaPlaces = data.doneHaftigaPlaces
      this.doneHaftigaPlacesData = data.doneHaftigaPlacesData
    },
    openSubmitDialog(haftigNumber) {
      this.dialog = true
      this.h_id = haftigNumber
    },
    closeSubmitDialog() {
      this.dialog = false
    },
    async fetchTeam() {
      const team = await this.getTeam()
      this.team = team
    },
  },
  mounted() {
    this.fetchTeam().then(() => {
      this.numHaftigaPlaces = parseInt(process.env.VUE_APP_MAX_HAFTIG)
      this.updateDoneHaftigaPlaces().then(() => {
        this.fetchingResults = false
      })
    })

    this.timer = setInterval(() => {
      this.updateDoneHaftigaPlaces()
    }, 5000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  computed: mapGetters(["notification", "haftigSubmissionLoading"])
}
</script>

<style>

</style>